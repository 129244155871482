/**
 * This is a re-write of
 * /django/contrib/admin/static/admin/js/related-widget-wrapper.js
 * Their's is kind of a mess and doesn't play well with our new
 * modularized format so we needed to convert it over..
 */
module.exports = {
  init: function() {
    // Related widget wrapper uses the GLOBAL.windowPopups() function
    // to popup the various windows, so we make sure to initialize it
    GLOBAL.windowPopups();

    var $widgetSelects = $('.related-widget-wrapper select');

    function updateLinks() {
      var $this = $(this);
      var siblings = $this.nextAll('.add-related, .change-related, .delete-related');

      if (!siblings.length) {
        return;
      }

      var value = $this.val();

      // We only want to fire the form when there is a value
      // that has been selected, otherwise we disable the
      // change/delete buttons
      if (value) {
        siblings.each(function() {
          var $elm = $(this);
          var href = $elm.attr('data-href-template');
          href = href.replace('__fk__', value).replace('_popup', 'popup');
          $elm.removeClass('disabled');
          $elm.data('href', href);
        });
      } else {
        siblings.each(function() {
          var $elm = $(this);
          if ($elm.hasClass('change-related') || $elm.hasClass('delete-related')) {
            $elm.addClass('disabled');
          } else if ($elm.hasClass('add-related')) {
            var href = $elm.attr('data-href-template');
            href = href.replace('__fk__', value).replace('_popup', 'popup');
            $elm.data('href', href);
          }
        });
        siblings.removeAttr('href');
      }
    }
    $widgetSelects.on('change', updateLinks);

    var widgetID = setTimeout(function() {
      $widgetSelects.each(updateLinks);
      clearTimeout(widgetID);
    }, 1000);
  }
};
