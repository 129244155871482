
module.exports = {
  init: function() {
    var $site = $('.l-sitewrap');
    var $dropdown = $('.js-dropdown');
    var $toggle = $($dropdown.find('.js-dropdown-toggle'));

    $toggle.on('click', function(e) {
      e.stopPropagation();
      $dropdown.toggleClass('is-open');
    });

    $site.on('click', function() {
      // Django debug toolbar is stupid and use $(document).on('click')
      // which means that the following stopPropagation() causes confusion
      // so that the toolbar never opens.
      // AMATEUR HOUR, YEESH!!
      // e.stopPropagation();
      $dropdown.removeClass('is-open');
    });
  }
};
