module.exports = {
  repopulateSelectizer: function(selector, selectizer, config, data) {
    /*
       Repopulates the selectizer widget and returns a reference to the selectizer
       instance (which can be used to repopulate it again later).

       Arguments:
       -selector: $(<select>) element we wish to repopulate (and then selectize)
       -selectizer: a reference to the selectizer instance. Created if null.
       -config: an object literal containing the selectize configuration options.
       -data: a list of object literals containing the value and text to use in
              each <option> tag.
       e.g.
       data: [{optionValue: value, optionDisplay: text}, ...]

       Passing selectizer=null will create a selectizer instance.
       Passing data = {} will create a dropdown with only the empty option.
       Passing data = null will cause the selectizer to populate the options in
                      the selector.

       Example Usage:
          selector = $('#some_selector');
          myConfig = {
            maxOptions: 10000,
            selectOnTab: true,
            allowEmptyOption: false,
            onChange: function(value) {
              myEventHandler(value);
            }
          };

          // Create a selectizer
          stizer = repopulateSelectizer(selector, null, myConfig, null);

          // Update the options in the selectizer
          stizer_reference = repopulateSelectizer(selector, stizer, myConfig, data);
     */

    /* I've changed this to just destroy itself, and then remake itself.
      It was the easiest way to replicate the reloadFromOriginalInput() custom function, that
      LIFT made.
      I don't use the "selectizer" attribute anymore, but oh well it's fine.
      Selectize doesn't have a "reloadFromOriginalInput" type function, which I think is
      ridiculous.
     */
    var selectizerRef;
    if (selector[0].selectize !== undefined) {
      /* Destroy the old selectize selector, if it exists. */
      selector[0].selectize.clearOptions();
      selector[0].selectize.disable();
      selector[0].selectize.destroy();
    }

    if (data !== null) {
      selector.empty();
      selector.append($('<option />').val('').text('--------'));
      var i;
      for (i = 0; i < data.length; i++) {
        selector.append($('<option />').val(data[i].optionValue).text(data[i].optionDisplay));
      }
      selector.prop('disabled', false);
    }

    /* Make another selectize element. */
    selectizerRef = selector.selectize(config)[0].selectize;
    return selectizerRef;
  }
};
