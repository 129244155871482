require('../vendor/jquery.tablesorter');

module.exports = {
  init: function() {
    var $tableSorts = $('.js-tableSorter');

    if ($tableSorts.length > 0) {
      $tableSorts.each(function() {
        $(this).tablesorter();
      });
    }
  }
};
