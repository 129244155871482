module.exports = {
  init: function() {
    $('form').find('input').keypress(function(e) {
      if ($(this).closest('form').prop('id') !== 'changelist-search') {
        if (e.which === 13) {
          var $targ = $(e.target);

          if (!$targ.is('textarea') && !$targ.is(':button,:submit')) {
            var focusNext = false;
            $(this).find(':input:visible:not([disabled],[readonly]), a').each(function() {
              if (this === e.target) {
                focusNext = true;
              } else if (focusNext) {
                $(this).focus();
                return false;
              }
            });

            return false;
          }
        }
      }
    });
    $('form').submit(function() {
      /* Handler submits the form (by returning true) and sets a
         new handler that returns false (prevents resubmission).
         This is not a case where we are worried about malicious
         resubmission, so disabling the submit client-side is good enough.
         Props https://stackoverflow.com/a/926863 */
      $(this).submit(function() {
        return false;
      });
      return true;
    });
  }
};
