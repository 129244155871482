/**
 * We have to move this outside of the main component as modules
 * cannot reference their own scope at initialization. Essentially,
 * it means that we can't have a Global CSRF value without moving
 * the function out here.
 */
function getCSRF(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function launchNotificationModal() {
  /* This launches the notification popup modal on every page in SL. Necessary, since we want the people to accept this
    popup when logging in.
   */
  $('#notification_popup_modal').modal('show');
}

// A fallback to window.onload, that will always work
window.addEventListener( 'load', launchNotificationModal );

module.exports = {
  // utility in case we need to list for events that propagate
  // all the way up to the body/sitewrap tag
  siteWrapper: $('.l-sitewrap'),

  // Universal datePicker configuration
  datePickerConfig: {
    timepicker: false,
    format: 'Y-m-d',
    scrollInput: false,
    closeOnDateSelect: true
  },

  // Universal timePicker configuration
  timePickerConfig: {
    datepicker: false,
    format: 'H:i:s',
    scrollInput: false,
    step: 15
  },

  selectizeConfig: {
    maxOptions: 10000,
    selectOnTab: true
  },

  // Universal multiSelect configuration
  multiSelectConfig: {
    selectableHeader: "<input type='text' placeholder='Search' class='search-input' autocomplete='off'>",
    selectionHeader: "<input type='text' placeholder='Search' class='search-input' autocomplete='off'>",
    selectableFooter: '<div class="form-footer js-select-all">Select All</div>',
    selectionFooter: '<div class="form-footer js-deselect-all">Deselect All</div>',
    afterInit: function() {
      var _this = this;
      var $selectableSearch = this.$selectableUl.prev();
      var $selectionSearch = this.$selectionUl.prev();
      var selectableSearchString = '#' + this.$container.attr('id') + ' .ms-elem-selectable:not(.ms-selected)';
      var selectionSearchString = '#' + this.$container.attr('id') + ' .ms-elem-selection.ms-selected';

      this.qs1 = $selectableSearch.quicksearch(selectableSearchString)
        .on('keydown', function(e) {
          if (e.which === 40) {
            _this.$selectableUl.focus();
            return false;
          }
        });

      this.qs2 = $selectionSearch.quicksearch(selectionSearchString)
        .on('keydown', function(e) {
          if (e.which === 40) {
            _this.$selectionUl.focus();
            return false;
          }
        });
    },
    afterSelect: function() {
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function() {
      this.qs1.cache();
      this.qs2.cache();
    }
  },

  /**
   * Generic function for printing a page. Just add the `.js-print` class to
   * any link element and it will automatically grab the `href` value and
   * print that url.
   */
  printing: function() {
    var _this = this;
    $('.js-print').on(this.clickHandler, function(e) {
      e.preventDefault();
      e.stopPropagation();
      _this.printPage(this.href);
    });
  },

  /**
   * Pops up a window and, when the window has finished loading, fires off a
   * print for the page
   */
  printPage: function(page) {
    var newWindow;
    var printReady;

    console.log('printing... ', page);
    newWindow = window.open(page + '?popup=1', '', 'width=600, height=800, _blank');

    var printAndClose = function() {
      if (newWindow.document.readyState === 'complete') {
        clearInterval(printReady);
        newWindow.print();
        console.log('page has been printed');
        newWindow.close();
      }
    };
    printReady = setInterval(printAndClose, 200);
  },


  /**
   * Generic function for launching new windows on the rare occasion we need
   * them for certain modules/functionality
   *
   * Usage: <a href="url.html" data-window-open="true">Popup Link</a>
   * Optional Attributes:
   *   data-href="" (in case you want to use a non-link element like button or span for the popup trigger)
   *   data-width=""
   *   data-name="" (window name, in case you need to popup multiple windows from the same view)
   *
   * @TODO: this may eventually be better through modals, particularly for making the
   * mobile experience consistent and fluid.
   */
  windowPopups: function() {
    var _this = this; // store current context
    var $popups = $('[data-window-open="true"]');
    $popups.each(function() {
      var $popup = $(this);
      $popup.on(_this.clickHandler, function(e) {
        var $this = $(this);
        if ($this.hasClass('disabled')) {
          // do nothing
        } else {
          var width = 'width=';
          var href = $this.data('href') ? $this.data('href') : $this.attr('href');
          var name = $this.data('name') ? $this.data('name') : 'sl_popup';
          width += ($this.data('width')) ? $this.data('width') : '700';
          e.preventDefault();
          e.stopPropagation();
          window.open(href, name, width + ',height=600,resizable=yes,scrollbars=no,status=1');
        }
      });
    });
  },

  /**
   * Utility function that returns a slugify'd version of a string
   *
   * @param  {string} Text to slugify
   * @return {string} Slugify'd string
   */
  slugify: function(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  },

  /** getCSRF
   *  CROSS SITE REQUEST FORGERY
   *  Only needed if you're doing AJAX
   *  @ref: https://docs.djangoproject.com/en/dev/ref/contrib/csrf/#ajax
   */
  getCSRF: function(name) {
    return getCSRF(name);
  },

  csrfSafeMethod: function(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
  },

  // store a global accessible CSRF Token
  csrftoken: getCSRF('csrftoken'),

  updateNotifications: function(pkList, updateType, endpointUrl, optionalCallbackFunction) {
    var callbackFunction = optionalCallbackFunction || $.noop;  // callbackFunction is optional
    var _this = this;
    $.ajax({                       // initialize an AJAX request
      url: endpointUrl,            // set the url of the request
      method: 'POST',
      traditional: true,           // Without this, 'notification-link-pks' becomes 'notification-link-pks[]'
      data: {
        'notification-link-pks': pkList,     // add the company id to the GET parameters
        'update-type': updateType           // update-type is either 'shown' or 'read'
      },
      beforeSend: function(xhr, settings) {
        if (!_this.csrfSafeMethod(settings.method) && !this.crossDomain) {
          xhr.setRequestHeader('X-CSRFToken', _this.csrftoken);
        }
      },
      success: callbackFunction()
    });
    /* Don't do anything on success or fail. The DB will be updated accordingly either way. */
  }
};

/* Run when the notification popup is shown. Track that it was shown to the user. */
$('#notification_popup_modal').on('shown.bs.modal', function() {
  var pkList = [];
  $('.notification-link-header').each(function() {
    pkList.push($(this).data('notification-link-pk'));
  });
  var updateType = 'shown';
  var url = $('#notification_popup_modal').data('notification-ajax-endpoint-url');

  module.exports.updateNotifications(pkList, updateType, url);
});

/* Run when the notification popup is closed. Track that the user acknowledged all the visible notifications.. */
$('#notification_popup_modal').on('hide.bs.modal', function() {
  var pkList = [];
  $('.notification-link-header').each(function() {
    pkList.push($(this).data('notification-link-pk'));
  });
  var updateType = 'read';
  var url = $('#notification_popup_modal').data('notification-ajax-endpoint-url');

  module.exports.updateNotifications(pkList, updateType, url);
});
