module.exports = {
  init: function() {
    var $forms = $('form');

    $forms.on('submit', function() {
      var $submits = $(this).find('[type="submit"], [name="cancel"], [name="delete"], [title="delete"]');
      $submits.each(function() {
        var $this = $(this);

        $this.addClass('is-disabled');

        window.setTimeout(function() {
          $this.removeClass('is-disabled');
        }, 800);
      });
    });
  }
};
