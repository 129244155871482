var ie10v = require('./ie10-viewport-hack');

module.exports = {
  init: function() {
    ie10v.init();
    this.sidebar();
    this.sidebarSubs();
  },

  sidebar: function() {
    var $sidebar = $('.sidebarMenu');
    var $toggle = $('.sidebarMenu-toggle');

    $sidebar.find('.sidebarMenu-wrapper').height(window.innerHeight);

    $toggle.on('click', function() {
      $sidebar.toggleClass('is-open');
    });
  },

  sidebarSubs: function() {
    var $subs = $('.sidebarMenu-list');

    $subs.each(function() {
      var $this = $(this);
      var $toggle = $this.find('.sidebarMenu-itemToggle');

      $toggle.on('click', function() {
        $this.toggleClass('is-active');
      });
    });
  }
};
