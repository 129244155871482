var pubsub = require('pubsub/pubsub.min.js');

module.exports = {
  $testsWrapper: null,
  $tests: null,
  $defaultTestIds: null,
  $cannot_edit_tests: null,
  $sureAudioCheckbox: $('input[name="tests"][value="268"]'),
  isEditView: null,

  init: function(isEditView) {
    this.$defaultTestIds = $('#default-test-ids').data('defaultTestIds');
    this.isEditView = isEditView;
    this.$cannot_edit_tests = $('#id_cannot_edit_appointment_tests').length > 0;

    if (isEditView) {
      this.constructLayout();
    } else {
      this.$tests = $('.form-testsElements label');
      this.groupTests();
    }
    /* Alert other modules that the sureAudio test was selected */
    var _this = this;
    this.$sureAudioCheckbox.on('change', function() {
      pubsub.publish('sureAudioTestSelected', {sureAudioTestSelected: _this.$sureAudioCheckbox[0].checked});
    });
  },

  /**
   * On our Edit Appointment page, it uses the default Django change_form
   * which our New Appointment page does not. As such, we need to construct
   * a little bit of layout that is done manually in the new_appointment template
   */
  constructLayout: function() {
    var $testsOutsideWrapper = $('.field-tests');
    this.$tests = $testsOutsideWrapper.find('label').slice(1);

    if (this.$cannot_edit_tests) {
      $testsOutsideWrapper.html('<label for="id_tests" class="col-sm-2">Tests:<br></label><div class="col-sm-10 form-testsElements"></div>');
    } else {
      $testsOutsideWrapper.html('<label for="id_tests" class="col-sm-2">Tests:<br><a href="#" id="testsToggle" class="btn btn--tiny btn--orange">Show All Test</a></label><div class="col-sm-10 form-testsElements"></div>');
    }

    this.groupTests();
  },

  /**
   * GroupTests takes all of the tests inside of the tests
   * form group and arranges them based on the title they
   * are given.
   */
  groupTests: function() {
    var uniqueTitles = [];
    var _this = this;

    this.$testsWrapper = $('.form-testsElements');

    // first things first, we have to find all of the unique
    // titles that the tests are assigned to
    this.$tests.each(function() {
      var title = $(this).attr('title');
      if ($.inArray(title, uniqueTitles) < 0) {
        uniqueTitles.push(title);
      }
    });

    // clean out the existing elements
    this.$testsWrapper.empty();

    // group similar elements based on unique titles
    $.each(uniqueTitles, function(index, value) {
      var elms = _this.$tests.filter('[title="' + value + '"]');
      _this.$testsWrapper.append(
        $('<h4>').addClass('form-testsHeading').text(value),
        $('<div>').addClass('form-testsGrouping').html(elms)
      );
    });

    // Select default tests defined by the template (if any)
    this.selectDefaultTests();

    // We hide all of the tests to start because defaults are
    // selected through the company/job type selection and this
    // helps make the whole page shorter overall
    this.cleanupTestDisplay();

    // Setup the test toggle button
    this.testsToggle();

    /* Commented out this conditional, because it was preventing
       updating of the default tests in the appointment edit view. */
    // Now that everything is setup properly, we want to listen
    // to our pub/sub system for any updates to job type
    // if (this.isEditView !== true) {
    //   this.defaultTestsPubSub();
    // }
    if (this.$cannot_edit_tests) {
      this.$tests.click(function() {return false; });
      this.$tests.prop('readonly', true);
    }
    this.defaultTestsPubSub();
  },

  /**
   * Select default tests that were specified in a data-default-test-ids attribute.
   */
  selectDefaultTests: function() {
    if (this.$defaultTestIds) {
      var testIds = $.map(this.$defaultTestIds, function(val) {
        return Number(val);
      });

      // I don't know why, but in Chrome (not Firefox) the edit appointments page loads
      // with some tests pre-selected, before it even gets here.  This occurs when you
      // click the History button then go back to the appointment (either browser back
      // button or the Go Back, doesn't matter).  I just clear any and all checked
      // inputs as a workaround.  But I hate workarounds. *sigh*
      this.$tests.find('input:checked').prop('checked', false);

      this.$tests
        .filter(function() {
          return $.inArray(Number($(this).children('input').val()), testIds) > -1;
        })
        .children('input').prop('checked', true);
    }
  },

  /**
   * Cleanup test display is called whenever a company or
   * job type is selected so that we can hide the non-default
   * checkboxes (and the headings without any defaults)
   */
  cleanupTestDisplay: function() {
    // hide all test checkboxes that aren't checked
    this.$tests
      .hide()
      // find the ones that are checked
      .filter(function() {
        return $(this).children('input').is(':checked');
      })
      // show 'em'
      .show();

    // hide all headings that don't have a checked input
    // as a child
    this.$testsWrapper
      .find('.form-testsHeading')
      .each(function() {
        var $this = $(this);
        if ($this.next('.form-testsGrouping').find('input:checked').length < 1) {
          $this.hide();
        } else {
          $this.show();
        }
      });

    pubsub.publish('defaultTestsComplete');
  },


  /**
   * TestsToggle takes care of showing/hiding the
   * rather enormous list of test options
   */
  testsToggle: function() {
    var $toggle = $('#testsToggle');
    var _this = this;

    $toggle.on('click', function(e) {
      e.stopPropagation();
      e.preventDefault();

      if ($toggle.hasClass('is-showing-tests')) {
        $toggle.removeClass('is-showing-tests');
        $toggle.text('Show All Tests');
        _this.cleanupTestDisplay();
      } else {
        $toggle.addClass('is-showing-tests');
        $toggle.text('Hide Tests');
        _this.$testsWrapper.find('.form-testsHeading').show();
        _this.$tests.show();
      }
    });

    // Only on the view for adding company job types, we want to
    // start the "group tests" as open and showing all of the
    // available tests
    if (window.location.href.indexOf('/schedule/companyjobtype/add/') > -1) {
      $toggle.addClass('is-showing-tests');
      $toggle.text('Hide Tests');
      _this.$testsWrapper.find('.form-testsHeading').show();
      _this.$tests.show();
    }
  },


  /**
   * Listen for events that indicate that Company/Job Type has been updated
   * and then modify the tests according to the information provided
   */
  defaultTestsPubSub: function() {
    var _this = this;

    // Subscribe to job type updates
    pubsub.subscribe('defaultTests', function(obj) {
      var ids = obj.test_ids;

      // Loop through all of the tests and compare
      // them against the ID array we got back
      _this.$tests.each(function() {
        var $input = $(this).find('input');
        // Deselect (in case the user had previously selected
        // a different job type or company)
        $input.prop('checked', false);

        if (ids.length > 0) {
          for (var i = ids.length - 1; i >= 0; i--) {
            // Check if we have a match, and set as checked if so
            if ($input.val() === '' + ids[i]) {
              $input.prop('checked', true);
            }
          }
        }
      });

      _this.cleanupTestDisplay();
      pubsub.publish('sureAudioTestSelected', {sureAudioTestSelected: _this.$sureAudioCheckbox[0].checked});
    });
  }
};
